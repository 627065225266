import { get, post, put, remove, patch } from "./api"
import { saveAs } from "file-saver"

export const getGPReports = (params) => {
  return get("/gp-reports", params)
}

export const createGPReport = (params) => {
  return post(
    `/gp-reports?closingStockTakeReportId=${
      params.closingStockTakeReportId
    }&openingStockTakeReportId=${params.openingStockTakeReportId}${
      params.salesId ? "&salesId=" + params.salesId : ""
    }`,
    params.filterCategories
  )
}

export const getGPReport = (gpReportId, params) => {
  return get(`/gp-reports/${gpReportId}`, params)
}

export const updateGPReport = (params) => {
  return put(
    `/gp-reports/${params.gpReportId}?closingStockTakeReportId=${
      params.closingStockTakeReportId
    }&openingStockTakeReportId=${params.openingStockTakeReportId}${
      params.salesId ? "&salesId=" + params.salesId : ""
    }`,
    params.filterCategories
  )
}

export const deleteGPReport = (gpReportId) => {
  return remove(`/gp-reports/${gpReportId}`)
}

export const exportGPReport = async (
  gpReportId,
  fileName,
  downloadFormat,
  params,
  subUrl
) => {
  const response = await get(
    `/gp-reports/${gpReportId}${subUrl}/export-as-${downloadFormat}?fileName=${fileName}`,
    params,
    {
      download: true,
    }
  )

  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.${downloadFormat}`)
  } else {
    return false
  }
}

export const updateProductOfGPReport = async (params) => {
  const { barcode, gpReportId, productPayload } = params
  return patch(
    `/gp-reports/${gpReportId}/product?barcode=${barcode}`,
    productPayload
  )
}

export const updateSubCategoryTotalsOfGPReport = async (params) => {
  const { category, gpReportId, subCategory, subCategoryPayload } = params
  return patch(
    `/gp-reports/${gpReportId}/subCategory-totals?category=${category}&subCategory=${subCategory}`,
    subCategoryPayload
  )
}

export const updateCategoryTotalsOfGPReport = async (params) => {
  const { category, categoryPayload, gpReportId } = params
  return patch(
    `/gp-reports/${gpReportId}/category-totals?category=${category}`,
    categoryPayload
  )
}
