import React, { useState, useContext, useEffect } from "react"
import { format, set } from "date-fns"
import { handleError } from "services/helpers"
import Loader from "components/common/Loader/Loader"
import { getStockTakes } from "services/stock-take"
import { Helmet } from "react-helmet"
import Header from "components/dashboard/Header/Header"
import { showError, showSuccess } from "services/toast"
import { navigate } from "gatsby-link"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { AsideContext } from "context/AsideContext"
import { GatewaySalesControllerApiFactory } from "services/typescript-fetch-client"
import { getBasePath, getParams } from "services/api"
import { GlobalStateContext } from "context/GlobalContextProvider"
import IntegrationsButton from "../IntegrationsButton/IntegrationsButton"

import * as styles from "./PosImport.module.css"
import { zonedTimeToUtc } from "date-fns-tz"
import { integrationConfig } from "services/constants"
import { integration } from "services/integrations"

const PosImport = () => {
  const { user, integrations } = useContext(GlobalStateContext)
  const aside = useContext(AsideContext)
  const [isLoading, setIsLoading] = useState(true)
  const [activeIntegration, setActiveIntegration] = useState<string | null>(
    null
  )
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: undefined,
    to: undefined,
    enteredTo: undefined,
  })
  const [selectedTime, setSelectedTime] = useState({
    from: "00:00",
    to: "23:59",
  })
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [stockDates, setStockDates] = useState<Date[]>([])
  const [stockLabels, setStockLabels] = useState([])

  const runImport = (
    type: string,
    fromDate: Date,
    toDate: Date,
    options?: any
  ) => {
    const controller = GatewaySalesControllerApiFactory(
      undefined,
      undefined,
      getBasePath()
    )

    switch (type) {
      case "SQUARE":
        return controller.createSalesFromSquareUsingPOST(
          fromDate,
          toDate,
          options
        )
      case "CLOVER":
        return controller.createSalesFromCloverUsingPOST(
          fromDate,
          toDate,
          options
        )
      case "LIGHTSPEED":
        return controller.createSalesFromLightspeedUsingPOST(
          fromDate,
          toDate,
          options
        )
      case "NEWBRIDGE":
        return controller.createSalesFromNewbridgeUsingPOST(
          fromDate,
          toDate,
          options
        )
      default:
        return controller.createSalesFromSquareUsingPOST(
          fromDate,
          toDate,
          options
        )
    }
  }

  /** Fetch activated integrations on mount */
  useEffect(() => {
    const abortController = new AbortController()

    const active = integrations?.posIntegrations.find(
      (i: integration) => i.status === "ACTIVE"
    )
    setActiveIntegration(active ? active.type : null)
    setIsLoading(false)

    return () => {
      setIsLoading(false)
      abortController.abort()
    }
  }, [])

  const toggleDatePicker = async () => {
    let selectedStockDates = stockDates
    let selectedStockLabels = stockLabels
    // @ts-ignore
    if (!selectedStockDates.length > 0) {
      const { newStockDates, newStockLabels } =
        await getStockTakeDatesAndLabels()
      selectedStockDates = newStockDates
      selectedStockLabels = newStockLabels
    }
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      selectedPeriod,
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
      highlightDays: selectedStockDates,
      highlightDaysLabels: selectedStockLabels,
    })
  }

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  const importSalesData = async () => {
    if (!activeIntegration) {
      setError("You have no active POS integration")
      return
    }

    setLoading(true)
    setError("")

    const timeFromSplit = selectedTime.from.split(":")
    const timeToSplit = selectedTime.to.split(":")

    const dateFrom = set(selectedPeriod.from, {
      hours: timeFromSplit[0],
      minutes: timeFromSplit[1],
    })
    const dateTo = set(selectedPeriod.to, {
      hours: timeToSplit[0],
      minutes: timeToSplit[1],
    })

    const convertedDateFrom = zonedTimeToUtc(dateFrom, user.zoneId)
    const convertedDateTo = zonedTimeToUtc(dateTo, user.zoneId)

    runImport(
      activeIntegration,
      convertedDateFrom,
      convertedDateTo,
      getParams()
    )
      .then((response) => {
        /**
         *  Timeout exceptions thrown by api fetch client do not aways get caught in this block.
            Checking for error statuses here to show user relevant messaging.

            Note: ts-ignore is added below because according to underlying code, no response with a status property should make it through to here
            however our theory is that they sometimes do on server timeout error. We can't really edit the types there because they are coming from
            code from the typescript-fetch-client code which is auto generated so any changes could be overwritten.
         */
        //@ts-ignore
        if (response.status >= 300) {
          showError(
            "Importing your sales data can take some time due to the high volume of data. You can leave this screen now and come back to it in the next few minutes.",
            {
              closeOnClick: true,
              autoClose: false,
            }
          )
          //@ts-ignore
        } else if (response.status >= 300) {
          handleError(response)
        } else {
          showSuccess("Sales data imported!")
          navigate("/dashboard/sales")
        }
      })
      .catch((error) => {
        if (error.status >= 300) {
          showError(
            "Importing your sales data can take some time due to the high volume of data. You can leave this screen now and come back to it in the next few minutes.",
            {
              closeOnClick: true,
              autoClose: false,
            }
          )
        } else {
          handleError(error)
        }
      })
      .finally(() => setLoading(false))
  }

  const getStockTakeDatesAndLabels = async () => {
    const request = await getStockTakes({
      status: "COMPLETED",
      sort: "createdAt,desc",
      size: 99,
    })

    const newStockDates = request.content.map(
      (stockItem) => new Date(stockItem.stockTakeReport.completedAt)
    )

    const newStockLabels = request.content.map((stockItem) => {
      return {
        label: stockItem.stockTakeReport.name,
        value: new Date(stockItem.stockTakeReport.completedAt),
      }
    })

    setStockDates(newStockDates)
    setStockLabels(newStockLabels)

    return { newStockDates, newStockLabels }
  }

  return (
    <>
      <Helmet>
        <title>POS import</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="POS import" />

        <div className={styles.content}>
          <Loader
            className="mt-20 absolute flex flex-col items-center left-1/2 text-center"
            style={{ transform: "translateX(-50%)" }}
            isLoading={isLoading}
          >
            Initializing..
          </Loader>
          {/* Date Picker */}
          {!isLoading ? (
            activeIntegration ? (
              <div className="mb-4 flex flex-col border rounded">
                <div className="w-full bg-gray-50 flex pt-2 pl-2 items-start">
                  <div className="mr-2 mt-1 bg-white rounded-full border flex items-center justify-center w-12 h-12">
                    <img
                      src={integrationConfig[activeIntegration].logo}
                      className="w-5"
                    />
                  </div>
                  <div>
                    <h5 className="mb-1 text-gray-800 text-lg">
                      Please select relevant period
                    </h5>
                    <p className="text-sm text-gray-700 mb-4">
                      Select the{" "}
                      <span className="capitalize">
                        {activeIntegration?.toLowerCase()}
                      </span>{" "}
                      sales period you want to import the sales for
                    </p>
                  </div>
                </div>
                <div className="border-t flex flex-col items-start p-3 md:p-4 relative">
                  <label className={styles.label}>Select period</label>
                  <div className="flex flex-row relative w-10/12 mb-4">
                    <div className="flex flex-col">
                      <div className={`input-container mr-4`}>
                        <input
                          type="text"
                          value={formatDate(selectedPeriod.from)}
                          className="cursor-pointer form-control rounded"
                          placeholder="From"
                          readOnly
                          onClick={toggleDatePicker}
                        />
                      </div>
                      <div className={`input-container mr-4 mt-2`}>
                        <input
                          type="time"
                          name="timeFrom"
                          className="cursor-pointer form-control rounded"
                          placeholder="From"
                          value={selectedTime.from}
                          onChange={(e) =>
                            setSelectedTime({
                              ...selectedTime,
                              from: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className={`input-container mr-2`}>
                        <input
                          type="text"
                          className="cursor-pointer form-control rounded"
                          value={formatDate(selectedPeriod.to)}
                          placeholder="To"
                          readOnly
                          onClick={toggleDatePicker}
                        />
                      </div>
                      <div className={`input-container mr-2 mt-2`}>
                        <input
                          type="time"
                          name="timeTo"
                          className="cursor-pointer form-control rounded"
                          placeholder="To"
                          value={selectedTime.to}
                          onChange={(e) =>
                            setSelectedTime({
                              ...selectedTime,
                              to: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {error && (
                    <div className="text-primaryPink pb-4">{error}</div>
                  )}
                  <button
                    type="submit"
                    disabled={
                      !selectedPeriod.to || !selectedPeriod.from || loading
                    }
                    onClick={importSalesData}
                    className="button button--autoWidth button--primaryGreen no-truncate"
                  >
                    Import Sales
                  </button>

                  <Loader
                    isLoading={loading}
                    style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
                  >
                    <div
                      style={{
                        textShadow:
                          "0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 40px white",
                      }}
                      className="text-sm"
                    >
                      <p>Your sales file is being imported.</p>
                      <p> It may take a few minutes.</p>
                      <p>Please wait.</p>
                    </div>
                  </Loader>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-start">
                <h3 className="text-primaryBlue font-bold mb-4 text-lg font-sansBold">
                  You have no active POS Integration.
                </h3>
                <IntegrationsButton />
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}

export default PosImport
