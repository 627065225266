import CustomSelect from "components/forms/CustomSelect"
import TextInput from "components/forms/TextInput"
import React, { useContext } from "react"
import { getProductMeasures, getRecipeMeasures } from "services/helpers"
import { percentageAndFullMeasures, recipeMeasures } from "services/constants"
import { Link } from "gatsby"
import { AsideContext } from "context/AsideContext"
import EditProduct from "components/suppliers/EditProduct/EditProduct"

const getProductRecipeMeasures = (
  element,
  staticMeasures: { label: string; value: string; type: string }[] = []
) => {
  if (element.product) {
    return getProductMeasures(element.product.measure).concat(staticMeasures)
  } else if (element.recipe) {
    return getRecipeMeasures(element.recipe)
  } else {
    return recipeMeasures
  }
}

export default ({
  element,
  path,
  disabled,
  onUpdate,
}: {
  element: any
  path: string
  disabled?: boolean
  onUpdate?: (data: any) => void
}) => {
  const aside = useContext(AsideContext)

  const handleEditProduct = (product) => {
    aside.showAside(EditProduct, {
      initialValues: product,
      headerText: "Edit product",
      onSubmitCallback: (data) => {
        if (onUpdate) {
          onUpdate(data)
        }
      },
    })
  }
  if (getProductRecipeMeasures(element).length > 0) {
    return (
      <>
        {/*<p>Quantity</p> */}
        <div className="flex items-stretch">
          <div className="input-container -mr-px" style={{ width: "5rem" }}>
            <TextInput
              required={true}
              name={`${path}.usedQty`}
              label="Quantity"
              type="number"
              step="any"
              disabled={disabled}
              value={element.usedQty}
              showValidations={false}
              placeholder="XX"
              className="form-control form-control--topleft form-control--bottomleft"
            />
          </div>

          <div
            className="input-container input-container--right"
            style={{ maxWidth: "100px" }}
          >
            <CustomSelect
              showValidations={false}
              required={false}
              name={`${path}.measure`}
              label="Measure"
              placeholder="Measure"
              isDisabled={disabled}
              defaultValue={element.measure ? element.measure : undefined}
              className="form-control form-control--topright form-control--bottomright w-28"
              options={getProductRecipeMeasures(
                element,
                percentageAndFullMeasures
              )}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <p className={"text-xs text-red-600"}>
        {element.product ? (
          <>
            Item missing size/measure.
            <button
              className="underline"
              onClick={(e) => {
                e.preventDefault()
                handleEditProduct(element.product)
              }}
            >
              Provide missing fields
            </button>{" "}
          </>
        ) : (
          <>
            Item missing yield/portions. Go to{" "}
            <Link
              className="underline"
              to="/dashboard/products/cookbook/recipes"
            >
              Products → Recipes
            </Link>{" "}
            to complete the set up
          </>
        )}
      </p>
    )
  }
}
